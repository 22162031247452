import {Component} from '@angular/core'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'
import {HeaderComponent} from './common/header/header.component'
import {RouterOutlet} from '@angular/router'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {AsyncPipe} from '@angular/common'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, ThemeModule, AsyncPipe]
})
export class AppComponent {
  public version = version.version
  constructor(public configService: ConfigService) {
  }
}
