{
  "name": "cert-app",
  "version": "1.2.2",
  "scripts": {
    "watch": "ng test",
    "build": "ng build",
    "lint": "ng lint",
    "ng": "ng",
    "install": "cp ./package.json ./src/assets",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  src/**/*.scss --fix",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.9",
    "@angular/cdk": "^18.2.10",
    "@angular/common": "^18.2.9",
    "@angular/compiler": "^18.2.9",
    "@angular/core": "^18.2.9",
    "@angular/forms": "^18.2.9",
    "@angular/material": "^18.2.10",
    "@angular/platform-browser": "^18.2.9",
    "@angular/platform-browser-dynamic": "^18.2.9",
    "@angular/router": "^18.2.9",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.10",
    "@angular-eslint/builder": "18.4.0",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/schematics": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cli": "~18.2.10",
    "@angular/compiler-cli": "^18.2.9",
    "@angular/material-luxon-adapter": "^18.2.10",
    "@sparbanken-syd/cert-backend": "^1.2.2-1518598807.0",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.1.1",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@types/jasmine": "~5.1.1",
    "@types/luxon": "^3",
    "@types/sparbanken-syd-auth-backend": "^2.0.2",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.4.3",
    "postcss": "^8.4.31",
    "stylelint": "^15.11.0",
    "stylelint-config-sass-guidelines": "^10.0.0",
    "stylelint-config-standard-scss": "^11.0.0",
    "typescript": "~5.5.4"
  }
}
