import {Routes} from '@angular/router'
import {HOME_ROUTE_PATH, LOGIN_ROUTE_PATH} from './application/data-types'
import {authGuard} from './application/auth.guard'

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: HOME_ROUTE_PATH,
    loadChildren: () => import('./home/home-routes').then(r => r.HOME_ROUTES),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadComponent: () => import('./common/login/login.component').then(c => c.LoginComponent)
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
] satisfies Routes