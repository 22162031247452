import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {first, Observable, switchMap} from 'rxjs'
import {ConfigService} from '../services/config.service'

/**
 * Inject to set auth header on all requests.
 */

export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn):
  Observable<HttpEvent<unknown>> => {
  const configService = inject(ConfigService)
  return configService.accessToken$.pipe(
    first(),
    switchMap(token => {
      if (!token) {
        return next(req)
      }
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      })
      return next(authReq)
    })
  )
}
