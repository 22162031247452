import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom} from '@angular/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {BrowserModule} from '@angular/platform-browser'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {authInterceptor} from './application/auth-interceptor'
import {responseInterceptor} from './application/response-interceptor'
import {ConfigService} from './services/config.service'
import {provideRouter, withHashLocation} from '@angular/router'
import routes from './app-routes'
import {provideAnimations} from '@angular/platform-browser/animations'

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, ThemeModule),
    provideRouter(routes,
      withHashLocation()),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    }
  ]
}

