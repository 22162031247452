import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {ConfigService} from '../services/config.service'


/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
/**
 * Intended to catch e.g. 401 responses and if so re-route to home.
 */
export const responseInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn):
  Observable<HttpEvent<unknown>> => {
  const configService = inject(ConfigService)
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        configService.reset()
      }
      return throwError(() => error)
    })
  )
}